import { AnalyticsClient } from '../../analytics/analyticsClient';
import { PracticeUtils } from '../../utils/utils';
import { usePracticeStaffControls } from '../account/state/usePracticeStaffControls';
import { SelectStaffRoot } from '../select-staff/SelectStaffRoot';
import { useSelectStaffAction } from '../select-staff/state/select-staff.actions';
import { ZendeskChatUnavailablePanelRoot } from './ZendeskChatUnavailablePanel/ZendeskChatUnavailablePanelRoot';
import { useChat } from './useChat';
import { useZendeskChat } from './useZendeskChat';
import { useGetDoctorPreferencesByIdQuery, usePartnerSessionForScannerQuery } from '@orthly/graphql-react';
import { useSession, useStaffMemberLoginProps } from '@orthly/session-client';
import { LoadBlocker } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

const ZendeskChat: React.VFC<{ staffMemberId: string | undefined | null }> = props => {
    const { value: enableCorrectStaffForiFrameChat = false } = useFeatureFlag('enableCorrectStaffForiFrameChat');
    const controls = usePracticeStaffControls();
    const { loadSessionFromLegacyToken } = useStaffMemberLoginProps();
    const staffMemberId = props.staffMemberId ?? controls?.employeePrefs?.staff_member_id ?? null;
    const [shouldInit, setShouldInit] = React.useState(false);
    const { isChatLoaded, isOutOfOfficeOpen } = useChat();
    const chatPortal = React.useRef(null);

    usePartnerSessionForScannerQuery({
        variables: { staff_member_id: staffMemberId },
        fetchPolicy: 'no-cache',
        skip: !staffMemberId || !enableCorrectStaffForiFrameChat,
        onCompleted: async data => {
            const impersonateToken = data?.partnerSessionForScanner.native_id_token;
            if (!impersonateToken) {
                return;
            }
            await loadSessionFromLegacyToken(impersonateToken);
            sessionStorage.removeItem('scannerToken');
            const prefId = controls?.employeePrefs?.id;
            if (data && prefId) {
                const user = data.partnerSessionForScanner.user;
                AnalyticsClient.identify(prefId, {
                    firstName: user.first_name,
                    lastName: user.last_name,
                    email: user.email || undefined,
                });
            }
            setShouldInit(true);
        },
    });

    useZendeskChat({
        autoOpen: true,
        embedded: true,
        portal: chatPortal.current,
        shouldInit: !enableCorrectStaffForiFrameChat || shouldInit,
    });

    if (isOutOfOfficeOpen) {
        return <ZendeskChatUnavailablePanelRoot open />;
    }
    return (
        <LoadBlocker blocking={!isChatLoaded}>
            <Grid container ref={chatPortal} style={{ height: '100vh' }} />
        </LoadBlocker>
    );
};

export const ZendeskChatSessionWrapper: React.VFC = () => {
    const { value: enableCorrectStaffForiFrameChat, loading } = useFeatureFlag('enableCorrectStaffForiFrameChat');
    const session = useSession();
    const history = useHistory();
    const logoutStaff = useSelectStaffAction('LOGOUT_STAFF');
    const prefId = PracticeUtils.getParsedQueryProperty('prefId', history.location.search);
    const staffMemberIdFromUrl = PracticeUtils.getParsedQueryProperty('staffMemberId', history.location.search);
    const pref = useGetDoctorPreferencesByIdQuery({
        variables: { doctor_id: prefId ?? '' },
        skip: !prefId || !!staffMemberIdFromUrl || !enableCorrectStaffForiFrameChat,
    });
    const staffMemberId = staffMemberIdFromUrl ?? pref?.data?.preferences?.staff_member_id;
    const loadPicker = !staffMemberId && !loading && enableCorrectStaffForiFrameChat;

    React.useEffect(() => {
        if (loadPicker) {
            logoutStaff();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staffMemberId, loading, enableCorrectStaffForiFrameChat]);

    if (session && !loading) {
        return (
            <>
                {loadPicker && <SelectStaffRoot />}
                <ZendeskChat staffMemberId={staffMemberId} />
            </>
        );
    }
    return null;
};
