import { formatWeeklyHours, isHoliday } from './utils';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

type Interval = {
    end_time: number;
    start_time: number;
};

type Holiday = {
    end_date: string;
    id: number;
    name: string;
    start_date: string;
};

interface ChatHoursProps {
    zendeskSchedule: Interval[];
    zendeskHolidays: Holiday[];
}

export const ChatHours: React.VFC<ChatHoursProps> = ({ zendeskSchedule, zendeskHolidays }) => {
    const formattedHours = zendeskSchedule.length > 0 ? formatWeeklyHours(zendeskSchedule) : [];
    const { isHoliday: todayIsHoliday, holidayName } = isHoliday(zendeskHolidays, new Date());
    return (
        <>
            {todayIsHoliday && (
                <Text variant={'body1'} medium align={'center'} sx={{ marginBottom: '32px' }}>
                    Today is a holiday, we are closed:
                    <Text variant={'body2'}>{holidayName}</Text>
                </Text>
            )}
            <Text variant={'body1'} medium align={'center'} sx={{ marginBottom: '8px' }}>
                Our live chat support hours are:
            </Text>
            {formattedHours.length > 0 &&
                formattedHours.map((hours, index) => (
                    <Text variant={'body2'} key={index}>
                        {hours}
                    </Text>
                ))}
            {formattedHours.length === 0 && (
                <Text variant={'body2'} color={'DARK_GRAY'} align={'center'} style={{ marginTop: 8 }}>
                    Monday through Friday 8AM to 9PM <br />
                    and Saturday 9AM to 4PM EST
                </Text>
            )}
        </>
    );
};
