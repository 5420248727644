import { PendingVariantFeedback_Fragment } from './Queries.graphql';
import { PracticeScreen } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useOrdersByIds } from '@orthly/graphql-react';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { FlossPalette, Grid, Text } from '@orthly/ui-primitives';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

interface PendingFeedbackRowProps {
    labOrderId: string;
    labOrderItemId: string;
    order: LabsGqlLabOrderFragment | undefined;
}

export const PendingFeedbackRow: React.VFC<PendingFeedbackRowProps> = ({ labOrderId, labOrderItemId, order }) => {
    const history = useHistory();

    if (!order) {
        return null;
    }

    const items = OrderItemV2Utils.parseItems(order?.items_v2 ?? []);
    const parsedItem = items.find(item => item.id === labOrderItemId);

    if (!parsedItem) {
        return null;
    }

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexFlow: 'row',
                borderBottom: `2px solid ${FlossPalette.STROKE_LIGHT}`,
                height: '80px',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Grid item container direction={'column'} xs={8}>
                <Grid item>
                    <Text
                        variant={'body1'}
                    >{`${order.patient.first_name} ${order.patient.last_name} - ${CartItemV2Utils.getItemDisplayInfo(parsedItem).full_display_name}`}</Text>
                </Grid>
                <Grid item>
                    <Text variant={'body2'}>{`Dr. ${order.doctor_name}`}</Text>
                </Grid>
            </Grid>
            <Grid item container direction={'column'} xs={2}>
                <Grid item>
                    <Text variant={'body2'}>Delivered</Text>
                </Grid>
                <Grid item>
                    <Text variant={'body1'}>{`${moment(order.created_at).format('MMM DD')}`}</Text>
                </Grid>
            </Grid>
            <Grid
                item
                xs={2}
                sx={{
                    background: FlossPalette.TAN,
                    border: `2px solid ${FlossPalette.DARK_TAN}`,
                    borderRadius: '8px',
                    padding: '12px 16px',
                    textAlign: 'center',
                    '&:hover': {
                        background: FlossPalette.WHITE,
                        cursor: 'pointer',
                    },
                }}
                onClick={() => {
                    history.push(`/${PracticeScreen.variant_feedback}/${labOrderId}/${labOrderItemId}`);
                }}
            >
                <Text medium color={'GREEN'}>
                    Provide Feedback
                </Text>
            </Grid>
        </Grid>
    );
};
interface PendingFeedbackListProps {
    pendingFeedback: FragmentType<typeof PendingVariantFeedback_Fragment>[];
}

export const PendingFeedbackList: React.VFC<PendingFeedbackListProps> = ({ pendingFeedback }) => {
    const pendingFeedbackData = getFragmentData(PendingVariantFeedback_Fragment, pendingFeedback);

    const { orders, loading } = useOrdersByIds(pendingFeedbackData.map(feedback => feedback.lab_order_id));

    return (
        <Grid
            container
            columnSpacing={1}
            sx={{ padding: '16px 48px', alignContent: 'flex-start', overflowY: 'scroll', overflowX: 'hidden' }}
        >
            <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                <Text variant={'h5'}>Which order would you like to leave feedback on?</Text>
            </Grid>
            {!loading &&
                orders &&
                pendingFeedbackData.map(feedback => (
                    <PendingFeedbackRow
                        key={feedback.lab_order_item_id}
                        labOrderId={feedback.lab_order_id}
                        labOrderItemId={feedback.lab_order_item_id}
                        order={orders.find(order => order?.id === feedback.lab_order_id)}
                    />
                ))}
        </Grid>
    );
};
