import { PracticeBannerProvider } from './PracticeBannerProvider.graphql';
import { PracticePortalRouter } from './PracticePortalRouter.graphql';
import { PracticeTopBanner } from './PracticeTopBanner';
import { LogoutButton } from './components/LogoutButton';
import { PracticeTopNav } from './components/PracticeTopNav';
import { ChatWindowRoot } from './screens/chat/ChatWindow/ChatWindowRoot';
import { ZendeskChatUnavailablePanelRoot } from './screens/chat/ZendeskChatUnavailablePanel/ZendeskChatUnavailablePanelRoot';
import { useChat } from './screens/chat/useChat';
import { useZendeskChat } from './screens/chat/useZendeskChat';
import { PracticeInboxProvider } from './screens/inbox/state/PracticeInboxProvider.graphql';
import { LabsContextProvider } from './screens/labs/LabsContext';
import { CurrentPartnerCtxProvider } from './utils/CurrentPartnerContext';
import { getPartnerId } from './utils/authorization';
import { SessionGuard, SessionGuardUtils, useSession } from '@orthly/session-client';
import { ErrorPage, LoadBlocker } from '@orthly/ui';
import { stylesFactory, useScreenIsMd, Grid } from '@orthly/ui-primitives';
import { OutageBanner } from '@orthly/veneer';
import React, { Suspense } from 'react';

const useStyles = stylesFactory(theme => ({
    headerWrapper: {
        position: 'fixed',
        zIndex: theme.zIndex.appBar,
    },
}));

const PartnerAppRootBase: React.VFC = () => {
    const session = useSession();
    const screenIsMedium = useScreenIsMd();
    const { isOutOfOfficeOpen, closeOutOfOffice } = useChat();

    useZendeskChat({ autoOpen: false });

    return (
        <SessionGuard
            test={SessionGuardUtils.hasOrgType(['lab', 'practice'])}
            fallback={
                <ErrorPage
                    message={'Unauthorized'}
                    iconName={'PanTool'}
                    text={"You don't have permission to see this."}
                >
                    <LogoutButton />
                </ErrorPage>
            }
        >
            <Suspense fallback={<LoadBlocker blocking={true} loader={'dandy'} />}>
                <CurrentPartnerCtxProvider partnerId={getPartnerId(session)}>
                    <LabsContextProvider>
                        {/* PracticeInboxProvider is here to ensure sidebar item count is up to date on all screens */}
                        <PracticeInboxProvider>
                            <PracticePortalRouter />
                            <ZendeskChatUnavailablePanelRoot open={isOutOfOfficeOpen} onClose={closeOutOfOffice} />
                        </PracticeInboxProvider>
                    </LabsContextProvider>
                    {/* Take out below OutageBanner when we init PracticeOutageBanner on mobile */}
                    {screenIsMedium && <OutageBanner audience={'practice'} />}
                    <ChatWindowRoot />
                </CurrentPartnerCtxProvider>
            </Suspense>
        </SessionGuard>
    );
};

export const PartnerAppRoot: React.VFC = () => {
    const classes = useStyles();

    return (
        <Grid container id={'partner-container'}>
            <PracticeBannerProvider>
                <Grid container className={classes.headerWrapper}>
                    <PracticeTopBanner />
                    <PracticeTopNav />
                </Grid>
                <PartnerAppRootBase />
            </PracticeBannerProvider>
        </Grid>
    );
};
