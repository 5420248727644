import { MobilePageLayout } from '../../../layouts/mobile/MobilePageLayout';
import { ChatWindowContent } from '../../../screens/chat/ChatWindow/ChatWindowContent';
import { useChatWindow } from '../../../screens/chat/ChatWindow/useChatWindow';
import { MiniWindowCore, stylesFactory, FlossPalette } from '@orthly/ui';
import React from 'react';

const useStyles = stylesFactory(() => ({
    window: {
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        backgroundColor: FlossPalette.TAN,
    },
}));

export const MobileChatRoot: React.VFC = () => {
    const classes = useStyles();
    const { windowVariant, windowPendingAnimation, maximizeWindow, setWindowPendingAnimation } = useChatWindow();

    React.useEffect(maximizeWindow, [maximizeWindow]);

    return (
        <MobilePageLayout title={'CST'} hideHeader>
            <MiniWindowCore
                variant={windowVariant}
                setVariant={() => {}}
                pendingAnimation={windowPendingAnimation}
                setPendingAnimation={setWindowPendingAnimation}
                className={classes.window}
            >
                <ChatWindowContent />
            </MiniWindowCore>
        </MobilePageLayout>
    );
};
