import type { PatientShareProps } from './PatientShare.types';
import { PatientShareScansModelViewer } from './PatientShareScansModelViewer';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import { Box, FlossPalette, Text } from '@orthly/ui-primitives';
import { DandyLightbox } from '@orthly/veneer';
import dayjs from 'dayjs';
import React from 'react';

export const PatientShareDesktop: React.VFC<PatientShareProps> = ({
    organizationName,
    organizationPhoneNumber,
    appointmentDate,
    appointmentTitle,
    message,
    previews,
    scanExport,
}) => {
    const [selectedAttachmentPreview, setSelectedAttachmentPreview] = React.useState<string | undefined>(undefined);

    const onAnnotationClickAction = (name: string) => {
        setSelectedAttachmentPreview(name);
        BrowserAnalyticsClientFactory.Instance?.track('Patient App - Share Scans - Annotation Clicked', {});
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', height: '100%' }}>
                <Box
                    sx={{
                        maxWidth: '440px',
                        padding: '64px 48px 0 48px',
                        borderRight: `1px solid ${FlossPalette.DARK_TAN}`,
                    }}
                >
                    <Box sx={{ marginBottom: '64px' }}>
                        <Text variant={'body2'} medium color={'BLACK'} sx={{ marginBottom: '0px' }}>
                            {organizationName}
                        </Text>
                        {organizationPhoneNumber && (
                            <Text variant={'body2'} color={'GRAY'}>
                                {PhoneNumberUtils.prettyPhoneNumber(organizationPhoneNumber)}
                            </Text>
                        )}
                    </Box>
                    <Box sx={{ marginTop: '24px' }}>
                        <Text variant={'body1'} medium color={'GRAY'} sx={{ marginBottom: '0px' }}>
                            {appointmentDate && dayjs(appointmentDate).format('MMMM D, YYYY')}
                        </Text>
                        <Text variant={'body1'} medium color={'BLACK'}>
                            {appointmentTitle}
                        </Text>
                    </Box>
                    {message && (
                        <Box
                            sx={{
                                backgroundColor: FlossPalette.TAN,
                                border: `1px solid ${FlossPalette.DARK_TAN}`,
                                borderRadius: '8px',
                                marginTop: '48px',
                                padding: '24px',
                            }}
                        >
                            <Text variant={'body2'} medium color={'BLACK'} sx={{ marginBottom: '16px' }}>
                                Dr's message:
                            </Text>
                            <Text variant={'body2'} color={'BLACK'} sx={{ whiteSpace: 'pre-line' }}>
                                {message}
                            </Text>
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        padding: '0 48px',
                        height: '100%',
                        width: '100%',
                        backgroundColor: FlossPalette.TAN,
                    }}
                >
                    <Box sx={{ maxWidth: '960px', marginTop: '64px', display: 'grid', gap: '40px' }}>
                        {previews && previews?.length > 0 && (
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                                    <Text variant={'h5'} medium color={'BLACK'}>
                                        Annotations
                                    </Text>
                                    <Text variant={'body2'} color={'GRAY'}>
                                        Click on an image to expand
                                    </Text>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gap: '8px',
                                        gridTemplateColumns: 'repeat(auto-fill, 470px)',
                                    }}
                                >
                                    {previews.map(preview => {
                                        return (
                                            <img
                                                onClick={() => onAnnotationClickAction(preview.name)}
                                                alt={'Annotation'}
                                                key={preview.name}
                                                src={preview.source}
                                                style={{
                                                    maxWidth: '470px',
                                                    borderRadius: '8px',
                                                    border: `1px solid ${FlossPalette.DARK_TAN}`,
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        );
                                    })}
                                </Box>
                            </Box>
                        )}
                        <Box>
                            <Box sx={{ display: 'flex', marginBottom: '24px' }}>
                                <Text variant={'h5'} medium color={'BLACK'}>
                                    Your scans
                                </Text>
                            </Box>
                            {scanExport && <PatientShareScansModelViewer scanExportFragment={scanExport} />}
                        </Box>
                    </Box>
                </Box>
            </Box>

            <DandyLightbox
                selectedAttachmentPreview={selectedAttachmentPreview}
                setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                previews={previews ?? undefined}
            />
        </Box>
    );
};
