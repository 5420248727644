import { zendeskScheduleAtom, zendeskHolidaysAtom } from '../graphql/useCheckCsSchedule.graphql';
import { ChatHours } from './ChatHours';
import {
    FlossPalette,
    useScreenIsMobile,
    Divider,
    IconButton,
    Slide,
    Icon,
    Button,
    Text,
    styled,
} from '@orthly/ui-primitives';
import FaviconBlack from '@orthly/ui/assets/logos/DandyFaviconDarkBlack.svg';
import { useAtom } from 'jotai';
import React from 'react';

const Root = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    right: 16,
    width: 380,
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.down('sm')]: {
        height: '100vh',
        width: '100vw',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.appBar - 1,
        background: FlossPalette.WHITE,
    },
}));

const Header = styled('div')({
    padding: '20px 24px',
    background: '#25484C',
    display: 'flex',
    justifyContent: 'space-between',
});

const HeaderWrapper = styled('div')({
    display: 'flex',
    width: '100%',
    alignContent: 'center',
});

const Body = styled('div')({
    padding: '24px',
    background: FlossPalette.WHITE,
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const Actions = styled('div')({
    flexWrap: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
});

const StyledIconButton = styled(IconButton)({
    background: 'none',
    margin: 0,
    padding: 0,
    borderRadius: '100%',
    '&:hover': {
        background: 'rgba(255, 255, 255, 0.08) !important',
        border: '1px solid transparent',
    },
    '&:focus': {
        background: 'none !important',
    },
    '&:active': {
        background: 'rgba(255, 255, 255, 0.12) !important',
    },
});

interface ZendeskChatUnavailableProps {
    open: boolean;
    onClose?: () => void;
}

export const ZendeskChatUnavailablePanelRoot: React.VFC<ZendeskChatUnavailableProps> = props => {
    const { open, onClose } = props;
    const isMobile = useScreenIsMobile();
    const [zendeskSchedule] = useAtom(zendeskScheduleAtom);
    const [zendeskHolidays] = useAtom(zendeskHolidaysAtom);

    return (
        <Slide direction={'up'} mountOnEnter unmountOnExit in={open} timeout={{ enter: 300, exit: 300 }}>
            <Root>
                <Header>
                    <HeaderWrapper>
                        <img src={FaviconBlack} alt={''} style={{ marginRight: 12 }} />
                        <Text variant={'body1'} medium color={'WHITE'}>
                            Chat with Dandy
                        </Text>
                    </HeaderWrapper>
                    {!isMobile && onClose && (
                        <StyledIconButton onClick={onClose}>
                            <Icon icon={'ChevronDown'} style={{ color: FlossPalette.WHITE }} />
                        </StyledIconButton>
                    )}
                </Header>
                <Body>
                    <ChatHours zendeskSchedule={zendeskSchedule} zendeskHolidays={zendeskHolidays} />
                    <Divider style={{ width: '100%', margin: '16px 0' }} />
                    <Text variant={'body2'} color={'DARK_GRAY'} align={'center'} style={{ marginBottom: 16 }}>
                        Need help now?
                    </Text>
                    <Actions>
                        <Button variant={'primary'} onClick={() => window.open('https://help.meetdandy.com', '_blank')}>
                            Visit the help center
                        </Button>
                        <Text variant={'body2'}>or</Text>
                        <Button
                            variant={'ghost'}
                            onClick={() => (window.location.href = 'mailto:doctors@meetdandy.com')}
                            style={{ padding: 0, height: 'unset' }}
                        >
                            Send us an email
                        </Button>
                    </Actions>
                </Body>
            </Root>
        </Slide>
    );
};
