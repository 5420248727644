import { useChatWindow } from './useChatWindow';
import { MiniWindowTitle } from '@orthly/ui';
import { FlossPalette, useScreenIsMobile, stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    backButton: {
        marginRight: 16,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        '&:hover': {
            border: `1px solid ${FlossPalette.LIGHT_GRAY}`,
        },
        '&:active .MuiSvgIcon-root': {
            color: `${FlossPalette.WHITE}`,
        },
    },
    mobileHeader: {
        paddingLeft: 24,
        paddingBottom: 16,
    },
}));

const ChatWindowGroupListHeader: React.VFC = () => {
    const classes = useStyles();
    const isMobile = useScreenIsMobile();

    const title = isMobile ? 'Questions? We’re here to help.' : 'Expert clinical support on demand';

    return (
        <Grid container style={{ paddingTop: 8 }} className={isMobile ? classes.mobileHeader : undefined}>
            <Text data-test={'cst-group-list-header'} variant={'h5'}>
                {title}
            </Text>
        </Grid>
    );
};

export const ChatWindowHeader: React.FC<{ onBack?: () => void }> = props => {
    const { windowVariant, platform, setWindowVariantWithAnimation } = useChatWindow();
    const isMobile = useScreenIsMobile();
    const classes = useStyles();
    const titleStyles = React.useMemo(() => {
        const styles: React.CSSProperties = { backgroundColor: FlossPalette.TAN };
        if (platform !== 'portal') {
            styles.padding = '16px 24px 8px';
        } else {
            styles.paddingLeft = 32;
            styles.paddingBottom = 8;
        }
        return styles;
    }, [platform]);

    return isMobile ? (
        <Grid container style={{ padding: '12px 8px' }}>
            <MiniWindowTitle
                variant={windowVariant}
                setVariant={setWindowVariantWithAnimation}
                onBack={props.onBack}
                hideControls
            >
                <ChatWindowGroupListHeader />
            </MiniWindowTitle>
        </Grid>
    ) : (
        <MiniWindowTitle
            variant={windowVariant}
            setVariant={setWindowVariantWithAnimation}
            style={titleStyles}
            onBack={props.onBack}
            hideControls
            disableMovableWindow={platform === 'portal'}
            closeIconStyles={{ width: 16 }}
            backButtonClasses={classes.backButton}
        >
            <ChatWindowGroupListHeader />
        </MiniWindowTitle>
    );
};
