import type { StarRatingType } from '../inbox/components/tracker/OrderSummaryStepper';
import { StarRatingInput } from '../inbox/components/tracker/OrderSummaryStepper';
import { SubmitVariantFeedback_Mutation } from './Queries.graphql';
import { useMutation } from '@apollo/client';
import { SimpleInput } from '@orthly/ui';
import {
    Text,
    FlossPalette,
    Grid,
    styled,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
    Button,
    FormControlLabel,
} from '@orthly/ui-primitives';
import React from 'react';

const CrownChoiceBox = styled(Grid)({
    border: `2px solid ${FlossPalette.STROKE_LIGHT}`,
    borderRadius: '16px',
    height: '260px',
    width: '260px',
    padding: '16px',
    textAlign: 'center',
    '&:hover': {
        cursor: 'pointer',
    },
});

interface OrderItemVariantFeedbackPageProps {
    orderId: string;
    orderItemId: string;
    afterSubmit: () => void;
}

export const OrderItemVariantFeedbackPage: React.VFC<OrderItemVariantFeedbackPageProps> = ({
    orderItemId,
    afterSubmit,
}) => {
    const [crownChoice, setCrownChoice] = React.useState<string | undefined>(undefined);
    const [wasDropIn, setWasDropIn] = React.useState<boolean | undefined>(undefined);
    const [timeToSeat, setTimeToSeat] = React.useState<string | undefined>(undefined);
    const [starRating, setStarRating] = React.useState<StarRatingType>(0);

    const [submit] = useMutation(SubmitVariantFeedback_Mutation);
    const submitFn = async () => {
        await submit({
            variables: {
                data: {
                    lab_order_item_id: orderItemId,
                    was_drop_in: false,
                    crown_choice: crownChoice,
                    time_to_seat: 'test',
                    star_rating: 5,
                },
            },
        }).then(() => afterSubmit());
    };

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexFlow: 'column',
            }}
        >
            <Grid item container direction={'column'} sx={{ padding: '16px', gap: '8px' }}>
                <Grid item>
                    <Text variant={'h5'}>Which crown did you seat?</Text>
                </Grid>
                <RadioGroup key={`RadioGroup-CrownChoice-${crownChoice}`} value={crownChoice}>
                    <Grid container item direction={'row'} sx={{ gap: '8px' }}>
                        <CrownChoiceBox container item direction={'column'} onClick={() => setCrownChoice('1')}>
                            <Radio sx={{ alignSelf: 'flex-start' }} value={1} />
                            <Text variant={'body1'}>Crown One</Text>
                        </CrownChoiceBox>
                        <CrownChoiceBox container item direction={'column'} onClick={() => setCrownChoice('2')}>
                            <Radio sx={{ alignSelf: 'flex-start' }} value={2} />
                            <Text variant={'body1'}>Crown Two</Text>
                        </CrownChoiceBox>
                        <CrownChoiceBox container item direction={'column'} onClick={() => setCrownChoice('3')}>
                            <Radio sx={{ alignSelf: 'flex-start' }} value={3} />
                            <Text variant={'body1'}>Crown Three</Text>
                        </CrownChoiceBox>
                    </Grid>
                </RadioGroup>
                <Grid item>
                    <Text variant={'h5'}>Did we deliver a drop-in crown?</Text>
                </Grid>
                <RadioGroup
                    key={`RadioGroup-WasDropIn-${wasDropIn}`}
                    value={wasDropIn}
                    onChange={changed => setWasDropIn(changed.target.value === 'true')}
                >
                    <Grid container item direction={'column'} sx={{ gap: '8px' }}>
                        <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
                        <FormControlLabel value={false} control={<Radio />} label={'No'} />
                    </Grid>
                </RadioGroup>
                <Grid item>
                    <Text variant={'h5'}>How long did it take to seat the crown?</Text>
                </Grid>
                <SimpleInput
                    TextFieldProps={{ multiline: false }}
                    fullWidth={false}
                    onChange={val => setTimeToSeat(val ?? '')}
                    value={timeToSeat}
                    label={'Minutes'}
                />
                <Grid item>
                    <Text variant={'h5'}>How would you rate this order?</Text>
                </Grid>
                <Grid item>
                    <StarRatingInput rating={starRating} onChange={setStarRating} />
                </Grid>
                <Grid item>
                    <Button variant={'primary'} onClick={submitFn}>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
