import type { HorizontalNavLeftSideButtonLinks, HorizontalNavRightSideButtonLinks } from '@orthly/dentin';
import { PracticeScreen } from '@orthly/dentin';
import { useFeatureFlag } from '@orthly/veneer';

export const usePartnerLeftNavButtonLinks = (): HorizontalNavLeftSideButtonLinks => {
    const { value: enablePracticePortalPatientsPage } = useFeatureFlag('enablePracticePortalPatientsPage');

    return {
        Home: PracticeScreen.inbox,
        Orders: PracticeScreen.orders,
        Patients: enablePracticePortalPatientsPage ? PracticeScreen.patients : undefined,
    };
};

export const partnerRightNavButtonLinks: HorizontalNavRightSideButtonLinks = {
    horizontalNavAccountSettingsLinks: {
        profileMenuLinks: {
            'Account settings': PracticeScreen.account_settings,
            Notifications: PracticeScreen.notifications,
            'Clinical preferences': PracticeScreen.preferences,
        },
        practiceMenuLinks: {
            Billing: PracticeScreen.billing,
            Staff: PracticeScreen.staff,
            'Pricing guide': PracticeScreen.pricing_guide,
            'Practice settings': PracticeScreen.practice_settings,
        },
    },
};
