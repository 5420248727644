import { StaffProfilePhoto } from '../../../components/StaffProfilePhoto';
import { useGetLoginOptions } from '../../../graphql/useGetLoginOptions.graphql';
import { MobileLinkGroup, mobileLinkGroupStyles } from '../../../layouts/mobile/MobileLinkGroup';
import { MobilePageLayout } from '../../../layouts/mobile/MobilePageLayout';
import { usePracticeLogout } from '../../../redux/auth';
import { useCurrentProfile, useCurrentProfileIsDoctor } from '../../../redux/selectors';
import { usePartnerUiSelector } from '../../../redux/ui';
import { usePracticeStaffControls } from '../../../screens/account/state/usePracticeStaffControls';
import { useSelectStaffAction } from '../../../screens/select-staff/state/select-staff.actions';
import { useMobileMoreStyles, useStyles } from './MobileMoreRoot.styles';
import { PracticeScreen } from '@orthly/dentin';
import { DoctorBadgeIcon } from '@orthly/ui';
import { Button, Grid, Text, useScreenIsMobile } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';

const MobileProfileRow: React.VFC = () => {
    const linkGroupClasses = mobileLinkGroupStyles({ single: true });
    const classes = useStyles();
    const logoutProfile = useSelectStaffAction('LOGOUT_STAFF');
    const profile = useCurrentProfile();
    const drSince = profile?.created_at && `Dandy doctor since ${moment(profile?.created_at).format('MM-DD-YYYY')}`;
    const controls = usePracticeStaffControls();
    const { loginOptions } = useGetLoginOptions(controls?.employeePrefs?.staff_member_id ?? '');
    const history = useHistory();
    const showSwitchLocation = loginOptions.length > 1;
    const isMobile = useScreenIsMobile();
    return (
        <Grid
            container
            className={classes.profileRowWrapper}
            style={
                isMobile && showSwitchLocation
                    ? { marginBottom: 0, paddingBottom: 0, justifyContent: 'space-between' }
                    : { justifyContent: 'space-between' }
            }
        >
            <Grid
                container
                className={linkGroupClasses.link}
                onClick={logoutProfile}
                style={{ padding: 0, width: 'auto', cursor: 'pointer' }}
            >
                <Grid item className={classes.staffWrapper}>
                    <StaffProfilePhoto staff={profile} style={{ fontSize: 18, width: 40, height: 40 }} />
                </Grid>
                <Grid item xs>
                    <Text variant={'h6'} className={classes.profileName}>
                        {profile?.formatted_name}
                    </Text>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <DoctorBadgeIcon className={classes.doctorBadgeIcon} />
                        <Text variant={'caption'} color={'GRAY'} className={classes.doctorSince}>
                            {drSince}
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
            {showSwitchLocation && (
                <Button
                    variant={'ghost'}
                    onClick={() => history.push('/login/select-location')}
                    startIcon={'SwapHorizontalIcon'}
                    style={{ marginLeft: 48 }}
                >
                    Switch location
                </Button>
            )}
        </Grid>
    );
};

export const MobileMoreRoot: React.VFC = () => {
    const classes = useMobileMoreStyles();
    const logOut = usePracticeLogout();
    const isDoctor = useCurrentProfileIsDoctor();
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);
    const getHref = (screen: PracticeScreen) => (isNativeApp ? `/${screen}?launch=pwa` : `/${screen}`);

    return (
        <MobilePageLayout title={'More'} hideHeader>
            <Grid container className={classes.root}>
                <MobileProfileRow />
                <MobileLinkGroup
                    header={'Supplies'}
                    subHeader={'Order supplies for your practice'}
                    linkItems={[
                        {
                            href: getHref(PracticeScreen.scanbodies),
                            title: 'Implant Readiness',
                        },
                        {
                            externalUrl: 'https://dandy.dental/chairside-dandy-accessories',
                            title: 'Accessories',
                        },
                        {
                            externalUrl: 'https://dandy.dental/chairside-dandy-tech',
                            title: 'Tech & Setup',
                        },
                        {
                            externalUrl: 'https://dandy.dental/chairside-dandy-marketing',
                            title: 'Marketing materials',
                        },
                    ]}
                />
                <MobileLinkGroup
                    header={'You'}
                    subHeader={'Manage your profile'}
                    linkItems={[
                        {
                            href: getHref(PracticeScreen.account_settings),
                            title: 'Account settings',
                        },
                        ...(isDoctor
                            ? [
                                  {
                                      href: getHref(PracticeScreen.notifications),
                                      title: 'Notifications',
                                  },
                                  {
                                      href: getHref(PracticeScreen.preferences),
                                      title: 'Clinical Preferences',
                                  },
                              ]
                            : []),
                    ]}
                />
                <MobileLinkGroup
                    header={'My practice'}
                    subHeader={'Manage your practice'}
                    linkItems={[
                        { href: getHref(PracticeScreen.billing), title: 'Billing' },
                        { href: getHref(PracticeScreen.staff), title: 'Staff' },
                        {
                            href: getHref(PracticeScreen.practice_settings),
                            title: 'Practice settings',
                        },
                        {
                            href: getHref(PracticeScreen.pricing_guide),
                            title: 'Pricing guide',
                        },
                        {
                            href: getHref(PracticeScreen.patients),
                            title: 'Patients',
                        },
                    ]}
                />

                <MobileLinkGroup
                    linkItems={[
                        {
                            externalUrl: 'https://help.meetdandy.com',
                            title: 'Help center',
                        },
                    ]}
                />
                <MobileLinkGroup linkItems={[{ onClick: logOut, title: 'Log out' }]} />
            </Grid>
        </MobilePageLayout>
    );
};
