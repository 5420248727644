import dayjs from 'dayjs';

const minutesToDayAndTime = (minutes: number): { day: string; time: string } => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayIndex = Math.floor(minutes / (24 * 60)); // Calculate day index (0 for Sunday, 1 for Monday, etc.)
    const timeMinutes = minutes % (24 * 60);
    const hours = Math.floor(timeMinutes / 60);
    const mins = timeMinutes % 60;
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = mins.toString().padStart(2, '0');
    return { day: days[dayIndex] ?? '', time: `${formattedHours}:${formattedMinutes} ${period}` };
};

export function formatWeeklyHours(intervals: { start_time: number; end_time: number }[]): string[] {
    // Helper function to convert minutes to day name and time

    // Create a map to hold hours for each day
    const dayHours: Record<string, string> = {
        Monday: 'Closed',
        Tuesday: 'Closed',
        Wednesday: 'Closed',
        Thursday: 'Closed',
        Friday: 'Closed',
        Saturday: 'Closed',
        Sunday: 'Closed',
    };

    // Populate the map with intervals
    intervals.forEach(({ start_time, end_time }) => {
        const start = minutesToDayAndTime(start_time);
        const end = minutesToDayAndTime(end_time);

        if (start.day === end.day) {
            // Interval is within one day
            dayHours[start.day] = `${start.time}–${end.time}`;
        }
    });

    // Group consecutive days with the same hours
    const groupedHours: string[] = [];
    const days = Object.keys(dayHours);
    let indexOuter = 0;

    while (indexOuter < days.length) {
        const startDay = days[indexOuter];
        const currentHours = startDay ? dayHours[startDay] : 'Closed';
        let indexInner = indexOuter;

        // Check for consecutive days with the same hours
        // @ts-expect-error
        while (indexInner + 1 < days.length && dayHours[days[indexInner + 1]] === currentHours) {
            indexInner++;
        }

        if (indexOuter === indexInner) {
            // Single day
            if (!(startDay === 'Sunday' && currentHours === 'Closed')) {
                groupedHours.push(`${startDay}: ${currentHours}`);
            }
        } else {
            // Range of days
            if (!(startDay === 'Sunday' && currentHours === 'Closed')) {
                groupedHours.push(`${days[indexOuter]} through ${days[indexInner]}: ${currentHours}`);
            }
        }

        indexOuter = indexInner + 1;
    }

    return groupedHours;
}

interface Holiday {
    isHoliday: boolean;
    holidayName: string | null;
}

export function isHoliday(
    holidays: { id: number; name: string; start_date: string; end_date: string }[],
    currentDate: Date,
): Holiday {
    const currentEasternDate = dayjs(currentDate).tz('America/New_York').format('YYYY-MM-DD');

    const isHoliday = holidays.some(
        holiday => currentEasternDate === holiday.start_date && currentEasternDate <= holiday.end_date,
    );
    return {
        isHoliday,
        holidayName: holidays.find(holiday => currentEasternDate === holiday.start_date)?.name ?? null,
    };
}
