import { practiceAnalyticsMiddleware } from './analytics/practice-analytics.middleware';
import { onboardingReducer } from './redux/onboarding/onboarding.reducer';
import { ordersReducer } from './redux/orders/orders.reducer';
import type { PartnerAppState } from './redux/types';
import { uiReducer } from './redux/ui';
import { alignerCheckoutReducer } from './screens/checkout-v2/state/reducers/aligners-checkout.reducer';
import { checkoutReducer } from './screens/checkout-v2/state/reducers/checkout.reducer';
import { dentureCheckoutReducer } from './screens/checkout-v2/state/reducers/dentures-checkout.reducer';
import { feedbackReducer } from './screens/feedback/state/feedback.reducer';
import { implantReadinessReducer } from './screens/implant_readiness/state/ImplantReadiness.reducer';
import { mobileAuthReducer } from './screens/mobile-auth/state/MobileAuth.reducer';
import { scanbodyRequestReducer } from './screens/scanbodies/state/ScanbodyRequest.reducer';
import { staffMemberReducer } from './screens/select-staff/state/select-staff.reducer';
import { tryInFeedbackReducer } from './screens/try_in_feedback/state/TryInFeedback.reducer';
import { createAsyncReducer } from '@orthly/redux-async-actions';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import thunk from 'redux-thunk';

const { createReduxHistory, routerReducer, routerMiddleware } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const reducer = combineReducers<PartnerAppState>({
    async: createAsyncReducer(false),
    alignerCheckout: alignerCheckoutReducer,
    checkout: checkoutReducer,
    dentureCheckout: dentureCheckoutReducer,
    onboarding: onboardingReducer,
    orders: ordersReducer,
    feedback: feedbackReducer,
    router: routerReducer,
    ui: uiReducer,
    scanbodyRequest: scanbodyRequestReducer,
    implantReadiness: implantReadinessReducer,
    tryInFeedback: tryInFeedbackReducer,
    staffMember: staffMemberReducer,
    mobileAuth: mobileAuthReducer,
});

const rootReducer = (state: PartnerAppState | undefined, action: AnyAction) => {
    return reducer(state, action);
};

export const initRedux = (initialState: Partial<PartnerAppState> = {}) => {
    const isDev = process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGING === 'true';
    const composeEnhancers =
        (isDev &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: false,
                traceLimit: 5,
            })) ||
        compose;
    const middleware = [routerMiddleware, thunk];
    if (window.analytics) {
        middleware.unshift(practiceAnalyticsMiddleware);
    }

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middleware), Sentry.createReduxEnhancer()),
    );

    const history = createReduxHistory(store);

    return { store, history };
};

// we export singletons so that we use the same store across the entire app
export const { store, history } = initRedux();

export const storeWithInitialState = (initialState: any) => initRedux(initialState);

if (window.Cypress || process.env.REACT_APP_NODE_ENV === 'test') {
    window.store = store;
}
