import { OrderItemVariantFeedbackPage } from './OrderItemVariantFeedbackRoot';
import { PendingFeedbackList } from './PendingFeedbacklist';
import type { PendingVariantFeedback_Fragment } from './Queries.graphql';
import { GetAllPendingFeedback_Query } from './Queries.graphql';
import { useQuery } from '@apollo/client';
import { OrderToolbar, PracticeFullScreenDialog, PracticeScreen } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { useOrdersByIds } from '@orthly/graphql-react';
import { useHistory, useParams } from 'react-router-dom';

interface PendingFeedbackContainerProps {
    pendingFeedback?: FragmentType<typeof PendingVariantFeedback_Fragment>[];
    loading: boolean;
}

const PendingFeedbackContainer: React.VFC<PendingFeedbackContainerProps> = ({ pendingFeedback, loading }) => {
    const history = useHistory();

    return (
        <>
            <OrderToolbar
                customTitle={'Perfect Fit Feedback'}
                hideProductImage={true}
                hideOrderNumber={true}
                onClose={() => history.push(`/${PracticeScreen.inbox}`)}
            />
            {!loading && (!pendingFeedback || pendingFeedback.length === 0) && <>{`No Pending Feedback`}</>}
            {!loading && pendingFeedback && pendingFeedback.length > 0 && (
                <PendingFeedbackList pendingFeedback={pendingFeedback} />
            )}
        </>
    );
};

interface OrderItemVariantFeedbackContainerProps {
    orderId: string;
    orderItemId: string;
    afterSubmit: () => void;
}

const OrderItemVariantFeedbackContainer: React.VFC<OrderItemVariantFeedbackContainerProps> = ({
    orderId,
    orderItemId,
    afterSubmit,
}) => {
    const history = useHistory();

    const { orders, loading } = useOrdersByIds([orderId]);
    const order = orders[0];

    const readyToGo = !loading && !!order;

    return (
        <>
            <OrderToolbar
                hideProductImage={!readyToGo}
                hideOrderNumber={!readyToGo}
                order={order}
                onClose={() => history.push(`/${PracticeScreen.variant_feedback}`)}
            />
            {readyToGo && (
                <OrderItemVariantFeedbackPage orderId={orderId} orderItemId={orderItemId} afterSubmit={afterSubmit} />
            )}
        </>
    );
};

export const VariantFeedbackRoot: React.VFC = () => {
    const history = useHistory();
    const { orderId, orderItemId } = useParams<{ orderId?: string; orderItemId?: string }>();
    const { data, loading, refetch } = useQuery(GetAllPendingFeedback_Query, {
        variables: {
            data: {}, // Don't need any query params (e.g. date filters) right now.
        },
        fetchPolicy: 'no-cache',
    });

    const afterSubmit = async () => {
        history.push(`/${PracticeScreen.variant_feedback}`);
        await refetch();
    };

    return (
        <PracticeFullScreenDialog dialogPaperStyle={{ overflow: 'hidden' }}>
            {(!orderId || !orderItemId) && (
                <PendingFeedbackContainer
                    pendingFeedback={data?.getAllPendingVariantFeedbackForPractice}
                    loading={loading}
                />
            )}
            {orderId && orderItemId && (
                <OrderItemVariantFeedbackContainer
                    orderId={orderId}
                    orderItemId={orderItemId}
                    afterSubmit={afterSubmit}
                />
            )}
        </PracticeFullScreenDialog>
    );
};
