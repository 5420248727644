import { graphql } from '@orthly/graphql-inline-react';

export const PendingVariantFeedback_Fragment = graphql(`
    fragment PendingVariantFeedback_Fragment on VariantFeedbackDTO {
        id
        practice_id
        status
        created_at
        lab_order_id
        lab_order_item_id
    }
`);

export const GetAllPendingFeedback_Query = graphql(`
    query GetAllPendingVariantFeedbackForPractice($data: GetAllPendingVariantFeedbackForPracticeInput!) {
        getAllPendingVariantFeedbackForPractice(data: $data) {
            ...PendingVariantFeedback_Fragment
        }
    }
`);

export const SubmitVariantFeedback_Mutation = graphql(`
    mutation SubmitVariantFeedback($data: SubmitVariantFeedbackInput!) {
        submitVariantFeedback(data: $data) {
            id
        }
    }
`);
