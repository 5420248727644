import { LoggedInContainerPractice } from './components/Containers';
import { MobileChatRoot } from './mobile/screens/chat/MobileChatRoot';
import { MobileMoreRoot } from './mobile/screens/more/MobileMoreRoot';
import { MobileSearchRoot } from './mobile/screens/search/MobileSearchRoot';
import { usePartnerUiSelector } from './redux/ui';
import { VariantFeedbackRoot } from './screens/variant_feedback/VariantFeedbackRoot.graphql';
import { useQuery } from '@apollo/client';
import { TrackWithMetadata } from '@orthly/analytics/dist/browser';
import { ATTACH_REFAB_FILES_PATH, PracticeScreen } from '@orthly/dentin';
import { graphql } from '@orthly/graphql-inline-react';
import { useSession } from '@orthly/session-client';
import { GuidedWaxupRoot, MobileRefabFlowFilesUploaderPage, useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const PartnerProfile = React.lazy(() =>
    import('./screens/account/PartnerProfile').then(module => ({
        default: module.PartnerProfile,
    })),
);
const PracticeSettings = React.lazy(() =>
    import('./screens/account/PracticeSettings').then(module => ({
        default: module.PracticeSettings,
    })),
);
const AcknowledgeAgreementDialog = React.lazy(() =>
    import('./screens/agreement/AcknowledgeAgreementDialog.graphql').then(module => ({
        default: module.AcknowledgeAgreementDialog,
    })),
);
const FeedbackPage = React.lazy(() =>
    import('./screens/feedback/FeedbackPage').then(module => ({
        default: module.FeedbackPage,
    })),
);
const AppointmentsRoot = React.lazy(() =>
    import('./screens/front-desk/AppointmentsRoot').then(module => ({
        default: module.AppointmentsRoot,
    })),
);
const SetupRoot = React.lazy(() =>
    import('./screens/front-desk/SetupRoot').then(module => ({
        default: module.SetupRoot,
    })),
);
const ImplantReadinessRoot = React.lazy(() =>
    import('./screens/implant_readiness/ImplantReadinessRoot').then(module => ({
        default: module.ImplantReadinessRoot,
    })),
);
const InboxRoot = React.lazy(() =>
    import('./screens/inbox/InboxRoot').then(module => ({
        default: module.InboxRoot,
    })),
);
const LabsRoot = React.lazy(() =>
    import('./screens/labs/LabsRoot').then(module => ({
        default: module.LabsRoot,
    })),
);
const PracticeOrderChatWrapper = React.lazy(() =>
    import('./screens/labs/components/PracticeOrderChatWrapper').then(module => ({
        default: module.PracticeOrderChatWrapper,
    })),
);
const NotificationLogsRoot = React.lazy(() =>
    import('./screens/notification_logs/NotificationLogsRoot').then(module => ({
        default: module.NotificationLogsRoot,
    })),
);
const Onboarding = React.lazy(() =>
    import('./screens/onboarding/Onboarding').then(module => ({
        default: module.Onboarding,
    })),
);
const PatientsRoot = React.lazy(() =>
    import('./screens/patients/PatientsRoot').then(module => ({
        default: module.PatientsRoot,
    })),
);
const PaymentMethodAndAddressRoot = React.lazy(() =>
    import('./screens/payment-and-address/PaymentMethodAndAddressRoot').then(module => ({
        default: module.PaymentMethodAndAddressRoot,
    })),
);
const RefabFlowRoot = React.lazy(() =>
    import('./screens/refab-flow/refab-flow-root').then(module => ({
        default: module.RefabFlowRoot,
    })),
);
const ScanbodiesRoot = React.lazy(() =>
    import('./screens/scanbodies/ScanbodiesRoot').then(module => ({
        default: module.ScanbodiesRoot,
    })),
);
const SelectStaffRoot = React.lazy(() =>
    import('./screens/select-staff/SelectStaffRoot').then(module => ({
        default: module.SelectStaffRoot,
    })),
);
const TryInFeedbackRoot = React.lazy(() =>
    import('./screens/try_in_feedback/TryInFeedbackRoot').then(module => ({
        default: module.TryInFeedbackRoot,
    })),
);
const PracticePortalRootQuery = graphql(`
    query PracticePortalRoot_Query($practice_id: String!) {
        practiceOrdersCount(practice_id: $practice_id)
        getOrCreatePracticeSupport(practice_id: $practice_id) {
            agreement_version
        }
    }
`);

const GetPracticeOnboardingQuery = graphql(`
    query GetPracticeOnboardingComplete {
        getPracticeOnboarding {
            portal_onboarding_complete
        }
    }
`);

function usePracticeRouterQuery(): { isOnboarding: boolean; agreementVersion: string | undefined; loading: boolean } {
    const { value: enableGuidedOnboarding } = useFeatureFlag('enableGuidedOnboarding');
    const practice_id = useSession()?.organization_id;
    // If the forceOnboarding flag is on, we display the onboarding
    // view for testing purposes
    const { value: forceOnboarding } = useFeatureFlag('forceOnboarding');
    const { data, loading } = useQuery(PracticePortalRootQuery, {
        variables: { practice_id: practice_id ?? '' },
        skip: !practice_id,
    });

    const { data: practiceOnboardingData, loading: practiceOnboardingLoading } = useQuery(GetPracticeOnboardingQuery);

    const practiceOrdersCount = data?.practiceOrdersCount;
    const agreementVersion = data?.getOrCreatePracticeSupport.agreement_version ?? undefined;

    // If the result is null, the practice predates the new onboarding system, and thus does not need to have their training completed.
    const needsToCompleteOnboarding =
        !!practiceOnboardingData?.getPracticeOnboarding &&
        !practiceOnboardingData?.getPracticeOnboarding?.portal_onboarding_complete;

    let isOnboarding = practiceOrdersCount === 0;

    if (loading && enableGuidedOnboarding && !practiceOnboardingLoading) {
        isOnboarding = false;
    }

    if (forceOnboarding || (enableGuidedOnboarding && needsToCompleteOnboarding)) {
        isOnboarding = true;
    }
    return {
        isOnboarding,
        agreementVersion,
        loading,
    };
}

export const PracticePortalRouter: React.FC = () => {
    const { isOnboarding, agreementVersion, loading } = usePracticeRouterQuery();
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);
    const { value: enableGuidedOnboarding, loading: enableGuidedOnboardingLoading } =
        useFeatureFlag('enableGuidedOnboarding');
    const { value: enablePracticePortalPatientsPage, loading: enablePracticePortalPatientsPageLoading } =
        useFeatureFlag('enablePracticePortalPatientsPage');

    return (
        <LoggedInContainerPractice>
            <SelectStaffRoot />
            <AcknowledgeAgreementDialog agreementVersion={agreementVersion} loading={loading} />
            <Route
                render={({ location }) => (
                    <Switch location={location}>
                        <Route
                            path={`/${PracticeScreen.onboarding}`}
                            render={() => {
                                if (enableGuidedOnboardingLoading) {
                                    return null;
                                } else if (enableGuidedOnboarding) {
                                    return <Onboarding />;
                                } else {
                                    return <Redirect to={'/'} />;
                                }
                            }}
                        />
                        <Route path={`/${PracticeScreen.my_profile}`}>
                            <PartnerProfile />
                        </Route>
                        <Route path={`/${PracticeScreen.my_practice}`}>
                            <PracticeSettings />
                        </Route>
                        <Route path={[`/${PracticeScreen.orders}`, `/${PracticeScreen.lab}`]}>
                            <LabsRoot />
                        </Route>
                        <Route path={`/${PracticeScreen.setup}`}>
                            <SetupRoot />
                        </Route>
                        <Route
                            path={`/${PracticeScreen.patients}`}
                            render={() => {
                                if (enablePracticePortalPatientsPageLoading) {
                                    return null;
                                } else if (enablePracticePortalPatientsPage) {
                                    return <PatientsRoot />;
                                } else {
                                    return <Redirect to={'/'} />;
                                }
                            }}
                        />
                        <Route path={`/${PracticeScreen.appointments}`}>
                            <AppointmentsRoot />
                        </Route>
                        <Route path={`/${PracticeScreen.scanbody_inventory}`}>
                            <TrackWithMetadata title={isNativeApp ? 'Mobile Scan bodies' : 'Scan bodies'}>
                                <ScanbodiesRoot />
                            </TrackWithMetadata>
                        </Route>
                        <Route path={`/${PracticeScreen.scanbodies}`}>
                            <TrackWithMetadata title={isNativeApp ? 'Mobile Implant Readiness' : 'Implant Readiness'}>
                                <ImplantReadinessRoot />
                            </TrackWithMetadata>
                        </Route>
                        <Route path={`/${PracticeScreen.inbox}`}>
                            <TrackWithMetadata title={isNativeApp ? 'Mobile Inbox' : 'Inbox'}>
                                <InboxRoot showOnboardingInbox={isOnboarding} loading={loading} />
                            </TrackWithMetadata>
                        </Route>
                        <Route path={`/${PracticeScreen.chat}`}>
                            <TrackWithMetadata title={isNativeApp ? 'Mobile Native Chat' : 'Mobile Chat'}>
                                <MobileChatRoot />
                            </TrackWithMetadata>
                        </Route>
                        <Route path={`/${PracticeScreen.notification_logs}`}>
                            <TrackWithMetadata title={isNativeApp ? 'Mobile Notification Logs' : 'Notification Logs'}>
                                <NotificationLogsRoot />
                            </TrackWithMetadata>
                        </Route>
                        <Route path={`/${PracticeScreen.search}`}>
                            <TrackWithMetadata title={isNativeApp ? 'Mobile Native Search' : 'Mobile Search'}>
                                <MobileSearchRoot />
                            </TrackWithMetadata>
                        </Route>
                        <Route path={`/${PracticeScreen.more}`}>
                            <TrackWithMetadata title={isNativeApp ? 'Mobile Native More' : 'Mobile More'}>
                                <MobileMoreRoot />
                            </TrackWithMetadata>
                        </Route>
                        <Route path={`/${PracticeScreen.feedback}/:lab_order_id`}>
                            <TrackWithMetadata
                                title={isNativeApp ? 'Mobile Feedback' : 'Feedback'}
                                metadataProps={params => ({
                                    $groups: {
                                        order: params.lab_order_id,
                                    },
                                })}
                            >
                                <FeedbackPage />
                            </TrackWithMetadata>
                        </Route>
                        <Route
                            path={`/${PracticeScreen.dentures_feedback}/:orderId`}
                            render={props => (
                                <Redirect to={`/${PracticeScreen.try_in_feedback}/${props.match.params.orderId}`} />
                            )}
                        />
                        <Route
                            path={`/${PracticeScreen.partials_feedback}/:orderId`}
                            render={props => (
                                <Redirect to={`/${PracticeScreen.try_in_feedback}/${props.match.params.orderId}`} />
                            )}
                        />
                        <Route path={`/${PracticeScreen.try_in_feedback}/:orderId`}>
                            <TryInFeedbackRoot />
                        </Route>
                        <Route path={`/${PracticeScreen.refab_flow}/:orderId/${ATTACH_REFAB_FILES_PATH}/items/:itemId`}>
                            <MobileRefabFlowFilesUploaderPage />
                        </Route>
                        <Route path={`/${PracticeScreen.refab_flow}/:orderId/${ATTACH_REFAB_FILES_PATH}`}>
                            <MobileRefabFlowFilesUploaderPage />
                        </Route>
                        <Route path={`/${PracticeScreen.refab_flow}/:orderId`}>
                            <RefabFlowRoot />
                        </Route>
                        <Route path={`/${PracticeScreen.guided_waxup}/:orderId/:revisionId?`}>
                            <GuidedWaxupRoot OrderChatWrapper={PracticeOrderChatWrapper} />
                        </Route>
                        <Route path={`/${PracticeScreen.payment_methods_and_addresses}`}>
                            <PaymentMethodAndAddressRoot />
                        </Route>
                        <Route
                            path={[
                                `/${PracticeScreen.variant_feedback}/:orderId?/:orderItemId?`,
                                `/perfect-fit-feedback'/:orderId?/:orderItemId?`,
                            ]}
                        >
                            <VariantFeedbackRoot />
                        </Route>
                        <Route
                            exact
                            path={`/invoices/:invoiceDetailId`}
                            render={props => {
                                return (
                                    <Redirect
                                        from={`/invoices/:invoiceDetailId`}
                                        to={`/${PracticeScreen.billing}/${props.match.params.invoiceDetailId}`}
                                    />
                                );
                            }}
                        />
                        <Redirect exact from={'/invoices'} to={PracticeScreen.billing} />
                        <Redirect to={{ pathname: `/${PracticeScreen.inbox}`, search: location.search }} />
                    </Switch>
                )}
            />
        </LoggedInContainerPractice>
    );
};
