import type { PatientShareProps } from './PatientShare.types';
import { PatientShareScansModelViewer } from './PatientShareScansModelViewer';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import { Box, FlossPalette, styled, Text } from '@orthly/ui-primitives';
import { DandyLightbox } from '@orthly/veneer';
import dayjs from 'dayjs';
import React from 'react';

const Divider = styled('div')({
    width: '100%',
    height: '1px',
    background: FlossPalette.DARK_TAN,
});

const Container = styled(Box)({
    padding: '24px 16px 54px 16px',
    background: FlossPalette.TAN,
    width: '100%',
    display: 'flex',
    gap: '24px',
    flexDirection: 'column',
    overflow: 'scroll',
});

const MessageBox = styled(Box)({
    background: FlossPalette.WHITE,
    padding: '24px',
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: '0 8px 8px 8px',
});

const ContentBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
});

export const PatientShareMobile: React.VFC<PatientShareProps> = ({
    organizationName,
    organizationPhoneNumber,
    appointmentDate,
    appointmentTitle,
    message,
    previews,
    scanExport,
}) => {
    const [selectedAttachmentPreview, setSelectedAttachmentPreview] = React.useState<string | undefined>(undefined);

    const onAnnotationClickAction = (name: string) => {
        setSelectedAttachmentPreview(name);
        BrowserAnalyticsClientFactory.Instance?.track('Patient App - Share Scans - Annotation Clicked', {});
    };

    return (
        <Container>
            <Box>
                <Text variant={'body2'} medium>
                    {organizationName}
                </Text>
                <Text variant={'body2'} color={'GRAY'}>
                    {PhoneNumberUtils.prettyPhoneNumber(organizationPhoneNumber ?? '')}
                </Text>
            </Box>

            <ContentBox>
                <Box>
                    <Text variant={'body1'} medium color={'GRAY'}>
                        {appointmentDate && dayjs(appointmentDate).format('MMMM D, YYYY')}
                    </Text>
                    <Text variant={'body1'} medium>
                        {appointmentTitle}
                    </Text>
                </Box>

                {message && (
                    <MessageBox>
                        <Text variant={'body2'} medium style={{ marginBottom: '12px' }}>
                            Dr's message:
                        </Text>
                        <Text variant={'body2'} style={{ whiteSpace: 'pre-line' }}>
                            {message}
                        </Text>
                    </MessageBox>
                )}

                {previews && previews.length > 0 && (
                    <>
                        <Divider />

                        <Box sx={{ display: 'grid', gap: '16px' }}>
                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
                                <Text variant={'body2'} medium>
                                    Annotations
                                </Text>
                                <Text variant={'body2'} color={'GRAY'}>
                                    Tap on an image to expand
                                </Text>
                            </Box>

                            <Box sx={{ display: 'grid', gap: '8px' }}>
                                {previews.map(({ source, name }, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            border: `1px solid ${FlossPalette.DARK_TAN}`,
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <img
                                            src={source}
                                            alt={'Annotation'}
                                            onClick={() => onAnnotationClickAction(name)}
                                            style={{ width: '100%', borderRadius: '8px', cursor: 'pointer' }}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </>
                )}

                {scanExport && (
                    <>
                        <Divider />

                        <Box sx={{ display: 'grid', gap: '16px' }}>
                            <Text variant={'body2'} medium>
                                Your scans
                            </Text>

                            <PatientShareScansModelViewer scanExportFragment={scanExport} />
                        </Box>
                    </>
                )}
            </ContentBox>

            <DandyLightbox
                selectedAttachmentPreview={selectedAttachmentPreview}
                setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                previews={previews ?? undefined}
            />
        </Container>
    );
};
