import type { ItemSearchControls, OrderSearchResults } from '../../../hooks';
import { useOrderSearchAutocomplete } from '../../../hooks';
import type { SavedSearchItem } from '../DandySearchPresenter.types';
import _ from 'lodash';
import React from 'react';

export interface UseDandySearchToolsArgs {
    setSearch: (value: string) => void;
    setIsDebounced: (value: boolean) => void;
    useOrderSearchResults: (controls?: ItemSearchControls) => OrderSearchResults;
    savedSearchData: SavedSearchItem[];
    setOpen?: (value: boolean) => void;
    onSearchAnalyticsAction: (value: string) => void;
}
export function useDandySearchTools({
    setSearch,
    setIsDebounced,
    useOrderSearchResults,
    savedSearchData,
    setOpen,
    onSearchAnalyticsAction,
}: UseDandySearchToolsArgs) {
    const { loading, items: orders, onInputChange } = useOrderSearchAutocomplete(() => {}, useOrderSearchResults);

    const debouncedOnChange = React.useMemo(() => {
        const onChange = (value: string) => {
            onSearchAnalyticsAction(value);
            onInputChange(value);
            setIsDebounced(false);
        };

        return _.debounce(onChange, 200);
    }, [onInputChange, setIsDebounced, onSearchAnalyticsAction]);

    const shouldShowSearchOptions = (value: string) => {
        return value.length >= 2 || (value.length < 2 && savedSearchData.length > 0);
    };

    const onInputChangeAction = (value: string) => {
        debouncedOnChange(value);
        setOpen?.(shouldShowSearchOptions(value));
        setSearch(value);
        setIsDebounced(true);
    };

    const onInputFocusAction = (value: string) => {
        setOpen?.(shouldShowSearchOptions(value));
        onInputChange(value);
        setSearch(value);
    };

    const onInputClearAction = () => {
        setOpen?.(false);
        onInputChange('');
        setSearch('');
        setIsDebounced(false);
    };

    return {
        debouncedOnChange,
        onInputChangeAction,
        onInputFocusAction,
        onInputClearAction,
        orders,
        loading,
    };
}
